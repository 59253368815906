import { cn } from '@lemonenergy/lemonpie-3'
import { Slot } from '@radix-ui/react-slot'
import type { HTMLAttributes } from 'react'

import type { AttributesSlottable } from 'types/utils'

const Layout = ({
  children,
  className,
  asChild,
  ...props
}: HTMLAttributes<HTMLDivElement> & AttributesSlottable<{}>) => {
  const Component = asChild ? Slot : 'main'

  return (
    <Component
      className={cn('flex flex-col flex-1 h-full', className)}
      {...props}
    >
      {children}
    </Component>
  )
}
export default Layout
